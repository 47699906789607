import $ from 'jquery';
import anchorScroll from 'anchor-scroll';
import Plyr from 'plyr';

$(() => {
    anchorScroll.init({
        updateUrl: false,
        duration: 600,
        offset: 0,
        ease: 'out-cube'
    });

    $(window).scroll(() => {
        const scroll = $(window).scrollTop();
        const os = $('#home').offset().top;
        const ht = $('#home').height();

        if (scroll > os + ht) {
            $('#nav').addClass('is-white');
        }
        else {
            $('#nav').removeClass('is-white');
        }
    });

    $('#burger').click(() => {
        $('#navMenu').toggleClass('is-active');
    });
});

// mainly for create custom player of highlight video
// https://github.com/sampotts/plyr
// https://codepen.io/sampotts/pen/jARJYp
document.addEventListener('DOMContentLoaded', () => {
    // This is the bare minimum JavaScript. You can opt to pass no arguments to setup.
    const player1 = new Plyr('#player1');
    const player2 = new Plyr('#player2');
    const player3 = new Plyr('#player3');
    const player4 = new Plyr('#player4');
});

if (window.location.pathname === '/reward-program/') {
    require('./reward-program');
}