
import axios from 'axios';

// const baseURL = process.env.LAMBDA_ENDPOINT;
const baseURL = `${process.env.DEPLOY_URL}/.netlify/functions`;
console.log('baseURL', baseURL)

let isVerified = false;

// initialize Account Kit with CSRF protection
window.AccountKit_OnInteractive = function() {
  axios
    .get(`${baseURL}/account_kit`)
    .then(({ data: { appId, csrf, version } }) => {
      // console.log({ appId, csrf, version });
      AccountKit.init({
        appId,
        state: csrf,
        version,
        fbAppEventsEnabled: true
      });
    });
};

// login callback
function loginCallback(response) {
  console.log({ response });
  if (response.status === 'PARTIALLY_AUTHENTICATED') {
    var code = response.code;
    var csrf = response.state;
    // Send code to server to exchange for access token
    isVerified = true;
    document.getElementById('verifyBtn').disabled = true;
  }
  else if (response.status === 'NOT_AUTHENTICATED') {
    // handle authentication failure
    alert('NOT AUTHENTICATED');
  }
  else if (response.status === 'BAD_PARAMS') {
    // handle bad parameters
    alert('Not valid phone number');
  }
}

// phone form submission handler
function smsLogin() {
  var countryCode = document.getElementById('country_code').value;
  var phoneNumber = document.getElementById('phone_number').value;
  // console.log({countryCode, phoneNumber})
  AccountKit.login(
    'PHONE',
    { countryCode: countryCode, phoneNumber: phoneNumber }, // will use default values if not specified
    loginCallback
  );
}

// email form submission handler
function emailLogin() {
  var emailAddress = document.getElementById('email').value;
  AccountKit.login('EMAIL', { emailAddress: emailAddress }, loginCallback);
}


document.getElementById('verifyBtn').addEventListener('click', function(e) {
  e.preventDefault();
  smsLogin();
});


// https://stripe.com/docs/checkout#integration-custom
var handler = StripeCheckout.configure({
  key: process.env.STRIPE_PUBLISHABLE_KEY,
  // image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
  locale: 'auto',
  // got token from stripe
  token: function(token) {
    const userId =  require('shortid').generate();
    var countryCode = document.getElementById('country_code').value;
    var phoneNumber = document.getElementById('phone_number').value;
    const payload = { countryCode: countryCode, number: phoneNumber, userId };
    
    // charge $ from backend
    axios.post(`${baseURL}/stripe`, token)
      .then(({data: chargeData})=> {
        // charge success!
        // chargeData
        payload.paymentInfo = chargeData;
        // setup payment code
        document.getElementById('paymentCode').value = chargeData.id;

        // validation
        if(!document.getElementById('country_code').value){
          return alert('country code missing');
        }
        if(!document.getElementById('phone_number').value){
          return alert('number missing');
        }
        if(!document.getElementById('paymentCode').value){
          return alert('Payment Code missing');
        }
        
        // submit form
        document.getElementById('rewardForm').submit();
      })
      .then(()=>{
        // TODO: go to thank page?
        // alert('Sign up finish!');
      })
      .catch((error)=>{
        // console.log(error)
        alert(error.message);
      });
  }
});

// listen
document.getElementById('paymentBtn').addEventListener('click', function(e) {
  e.preventDefault();
  if(!isVerified){
    return alert('Please verified phone number before pay');
  }
  // Open Checkout with further options:
  handler.open({
    name: 'HoopCam Limited',
    // description: '2 widgets',
    currency: 'usd',
    amount: 500
  });
});
// document.getElementById('finishSignup').addEventListener('click', function(e) {

// });

// Close Checkout on page navigation:
window.addEventListener('popstate', function() {
  handler.close();
});